<template>
  <v-container id="crud" fluid tag="section">
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="showHeaders"
        :items="filteredData"
        :search="search"
        sort-by="secret"
        class="elevation-1"
      >
        <template v-slot:[`header.nombre`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.apellido`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.dni`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.legajo`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`header.email`]="{ header }">
          <filter-component
            v-bind:header="{ header }"
            v-bind:filters="filters"
          ></filter-component>
        </template>

        <template v-slot:[`item.activo`]="{ item }">
          <div :class="$getCss(item.activo, 'activo')" dark>
            {{ $getValue(item.activo, "activo") }}
          </div>
        </template>

        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="'Busqueda de ' + title"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <template v-slot:[`item.activo`]="{ item }">
          <div :class="$getCss(item.activo, 'activo')" dark>
            {{ $getValue(item.activo, "activo") }}
          </div>
        </template>

        <template v-slot:[`item.fecha_vencimiento`]="{ item }">
          {{ $formatDate(item.fecha_vencimiento) }}
        </template>

        <template v-slot:[`item.puntaje`]="{ item }">
          <v-rating
            :color="$cv('principal')"
            :value="parseInt(item.puntaje)"
            half-increments
            class="ma-0"
            density="compact"
            readonly
          ></v-rating>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="white" class="pr-4">
            <v-row>
              <v-col sm="6" class="pt-20">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="'Busqueda de ' + title"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col sm="2"></v-col>
              <v-col sm="3" class="">
                <v-autocomplete
                  v-model="selectedHeaders"
                  :items="headers"
                  label="Columnas Visibles"
                  multiple
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 2">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 2" class="grey--text caption"
                      >(otras {{ selectedHeaders.length - 2 }}+)</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col sm="1">
                <v-dialog v-model="dialog" max-width="50%" persistent>
                  <template v-if="visible" v-slot:activator="{ on, attrs }">
                    <v-btn
                      :color="$cv('principal')"
                      dark
                      class="mx-2"
                      fab
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon blue>mdi-plus</v-icon></v-btn
                    >
                  </template>
                  <v-card>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-toolbar dark :color="$cv('principal')">
                        <v-btn icon dark @click="dialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn dark text @click="$save()" :disabled="!valid"
                            >Guardar</v-btn
                          >
                        </v-toolbar-items>
                      </v-toolbar>

                      <v-card-text class="card-scrolleable">
                        <v-container>
                          <v-row>
                            <v-col md="6" sm="12">
                              <v-text-field
                                v-model="editedItem.dni"
                                label="DNI"
                                :rules="[$rulesDni]"
                                filled
                                dense
                                class="input-h1"
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" sm="12">
                              <v-text-field
                                v-model="editedItem.legajo"
                                label="Legajo"
                                :rules="[$rulesLegajo]"
                                filled
                                dense
                                class="input-h1"
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" sm="12">
                              <v-text-field
                                v-model="editedItem.nombre"
                                label="Nombre"
                                :rules="[$rulesRequerido]"
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" sm="12">
                              <v-text-field
                                v-model="editedItem.apellido"
                                label="Apellido"
                                :rules="[$rulesRequerido]"
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" sm="12">
                              <v-text-field
                                v-model="editedItem.email"
                                label="Email"
                                :rules="[$rulesRequerido, $rulesMail]"
                              ></v-text-field>
                            </v-col>
                            <v-col md="6" sm="6">
                              <!--<v-select
														v-model="editedItem.puntaje"
														:items="puntaje"
														label="Puntaje"
													></v-select>-->
                              <v-row
                                class="row row rounded-lg grey-lighten-2 ml-0 mr-0 pb-2"
                              >
                                <v-col
                                  md="2"
                                  sm="2"
                                  class="pa-0 pl-1 pt-2 pb-2"
                                >
                                  <p class="font-weight-light mt-2 mb-0">
                                    Puntaje:
                                  </p>
                                </v-col>
                                <v-col
                                  md="7"
                                  sm="7"
                                  class="pa-0 pl-0 pt-4 pr-0 pb-2"
                                  ><div class="text-center">
                                    <template>
                                      <v-rating
                                        :color="$cv('principal')"
                                        v-model="editedItem.puntaje"
                                        density="compact"
                                        label="Puntaje"
                                      ></v-rating>
                                    </template>
                                  </div>
                                </v-col>
                                <v-col md="1" sm="1" class="pa-0 pl-0 mt-5 pr-0"
                                  ><div class="text-center ma-0 pa-0">
                                    <p
                                      class="ma-0 pa-0 font-weight-black"
                                      style="font-size: 20px"
                                    >
                                      {{ editedItem.puntaje }}
                                    </p>
                                  </div></v-col
                                >
                              </v-row>
                            </v-col>
                            <v-col md="6" sm="6">
                              <v-autocomplete
                                :items="licencias"
                                item-value="id"
                                item-text="nombre"
                                label="Tipo de Licencia"
                                v-model="editedItem.tipo_licencia_id"
                              ></v-autocomplete>
                            </v-col>
                            <v-col md="6" sm="6">
                              <v-dialog
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="
                                  editedItem.fecha_vencimiento
                                "
                                persistent
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="computedVencimientoLicencia"
                                    label="Fecha de vencimiento"
                                    :rules="[$rulesRequerido]"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                    v-bind="attrs"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editedItem.fecha_vencimiento"
                                  :min="nowDate"
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    :color="$cv('principal')"
                                    @click="modal = false"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    :color="$cv('principal')"
                                    @click="
                                      $refs.dialog.save(
                                        editedItem.fecha_vencimiento
                                      )
                                    "
                                    >OK</v-btn
                                  >
                                </v-date-picker>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="is('administrador')"
            small
            :color="$cv('btnEditar')"
            title="Editar"
            @click="
              vista = false;
              $editItem(item.id);
            "
          >
            mdi-pencil
          </v-icon>

          <v-icon
            v-if="is('usuario')"
            small
            :color="$cv('btnVista')"
            title="Ver"
            @click="
              vista = true;
              $editItem(item.id);
            "
          >
            mdi-eye-outline
          </v-icon>

          <v-icon
            v-if="is('administrador')"
            small
            :color="item.activo != 0 ? $cv('btnActivo') : $cv('btnInactivo')"
            :title="
              item.activo != 0 ? $cv('titleDesactivar') : $cv('titleActivar')
            "
            @click="$enabledItem(item)"
          >
            mdi-archive-arrow-down
          </v-icon>
          <v-icon
            v-if="is('administrador')"
            small
            :color="$cv('btnEliminar')"
            title="Eliminar"
            @click="$deleteItem(item.id, item.apellido)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Choferes";
}

export default {
  data: (vm) => ({
    puntaje: ["1", "2", "3", "4", "5"],
    licencias: [],
    valid: true,
    nowDate: new Date().toISOString().slice(0, 10),
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    route: "choferes",
    menu: false,
    modal: false,
    menu2: false,
    dialog: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
    search: "",
    users: false,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Nombre", filterable: true, value: "nombre" },
      { text: "Apellido", filterable: true, value: "apellido" },
      { text: "DNI", filterable: true, value: "dni" },
      { text: "Legajo", filterable: true, value: "legajo" },
      { text: "Mail", filterable: true, value: "email" },
      { text: "Puntaje", filterable: true, value: "puntaje" },
      { text: "Activo", value: "activo" },
      { text: "Licencia", filterable: true, value: "licencia" },
      { text: "Vencimiento", filterable: true, value: "fecha_vencimiento" },
      { text: "Creado", value: "created_at" },
      { text: "Actualizado", value: "updated_at" },
      { text: "Acciones", value: "actions", sortable: false },
    ],

    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      dni: "",
      legajo: "",
      apellido: "",
      nombre: "",
      email: "",
      tipo_licencia_id: "",
      fecha_vencimiento: "",
    },
    defaultItem: {},
    filters: {
      nombre: "",
      apellido: "",
      dni: "",
      legajo: "",
      email: "",
    },
    filterKey: [],
    selectedHeaders: [],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : "Editar " + this.title;
    },
    computedVencimientoLicencia() {
      return this.$formatDate(this.editedItem.fecha_vencimiento);
    },
    filteredData() {
      return this.$filteredData();
    },
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s));
    },
  },

  watch: {
    dialog(val) {
      val || this.$close();
    },
  },

  mounted() {
    console.log("Componente " + this.title + " creado");
  },

  created() {
    this.$initialize();
    this.getLicencias();
    this.selectedHeaders = this.headers;
  },

  methods: {
    getLicencias() {
      this.$axiosApi
        .get("tipo_licencias")
        .then((response) => {
          this.licencias = response.data;
        })
        .catch((e) => {
          this.snackbar = true;
          this.text =
            "Error al cargar categoria de gastos. Error: " + e.message;
          this.color = "error";
        });
    },
    filterByNombre(item) {
      return this.$filterBy(item, "nombre");
    },
    filterByApellido(item) {
      return this.$filterBy(item, "apellido");
    },
    filterByDNI(item) {
      return this.$filterBy(item, "dni");
    },
    filterByLegajo(item) {
      return this.$filterBy(item, "legajo");
    },
    filterByEmail(item) {
      return this.$filterBy(item, "email");
    },
  },
  mounted() {
    console.log("Componente " + this.title + " creado");
  },
};
</script>
